let Swiper = require('swiper/dist/js/swiper.js');

$(function() {
    const displayString = function(endDate) {
        let units = ['Days', 'Hours', 'Minutes', 'Seconds'];

        const now = new Date();
        const dateDifference = endDate - now;

        if (dateDifference < 100 && dateDifference > 0) {
            return
        }

        let lastUnit = units[units.length - 1],
        unitConstantForMillisecs = {
            year: (((1000 * 60 * 60 * 24 * 7) * 4) * 12),
            month: ((1000 * 60 * 60 * 24 * 7) * 4),
            weeks: (1000 * 60 * 60 * 24 * 7),
            days: (1000 * 60 * 60 * 24),
            hours: (1000 * 60 * 60),
            minutes: (1000 * 60),
            seconds: 1000
        },
        unitsLeft = {},
        returnNumbers = [],
        totalMillisecsLeft = dateDifference,
        i,
        unit;

        for (i in units) {
            if (units.hasOwnProperty(i)) {
                unit = units[i].trim();
                if (unitConstantForMillisecs[unit.toLowerCase()] === false) {
                    return;
                }
                if (unitConstantForMillisecs.hasOwnProperty(unit.toLowerCase()) === false) {
                    return;
                }

                unitsLeft[unit] = totalMillisecsLeft / unitConstantForMillisecs[unit.toLowerCase()];

                if (lastUnit === unit) {
                    unitsLeft[unit] = Math.ceil(unitsLeft[unit]);
                } else {
                    unitsLeft[unit] = Math.floor(unitsLeft[unit]);
                }
                totalMillisecsLeft -= unitsLeft[unit] * unitConstantForMillisecs[unit.toLowerCase()];
                unitConstantForMillisecs[unit.toLowerCase()] = false;

                returnNumbers.push(unitsLeft[unit]);
            }
        }

        for (i in returnNumbers) {
            $('.measurements-number.index-' + i).text(returnNumbers[i]);
        }
    };

    let $countdown = $('.countdown');
    if ($countdown) {
        let endDate = $countdown.data('end-date');
        endDate = new Date(endDate);

        setInterval(() => {
            displayString(endDate);
        }, 100);
    }

    let swipeConfig = {
        slidesPerView: 6,
        spaceBetween: 0,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        preloadImages: false,
        breakpoints: {
            1800: {slidesPerView: 5},
            1500: {slidesPerView: 4},
            1285: {slidesPerView: 3},
            900: {slidesPerView: 2},
            500: {slidesPerView: 1}
        }
    };

    let hotelsSwipeConfig = {
        slidesPerView: 6,
        spaceBetween: 10,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        preloadImages: false,
        breakpoints: {
            992: {slidesPerView: 5},
            768: {slidesPerView: 4},
            600: {slidesPerView: 3},
            450: {slidesPerView: 2}
        }
    };

    let attractionSwipeConfig = {
        autoHeight: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        }
    };

    let attractionSwiper = new Swiper('.attractions', attractionSwipeConfig);

    let programSwiper = new Swiper('.day-program-gallery', attractionSwipeConfig);

    let swiper = new Swiper('.gallery-section', swipeConfig);

    let hotelsSwiper = new Swiper('.hotels-navigation', hotelsSwipeConfig);

    let $hotelPicture = $('.hotel-picture');
    if ($hotelPicture) {
        $hotelPicture.click(function() {
            let background = $(this).data('url');
            $(this).closest('.hotel-content').find('.preview-image img').attr('src', background);
        });
    }

    let $nextHotel = $('.hotel-nav-item');
    if ($nextHotel) {
        $nextHotel.click(function() {
            let section = $(this).data('section');
            let hotelIndex = $(this).data('hotel-index');

            let $nextHotel = $('.hotel-section-' + section + ' .hotel-' + (hotelIndex));
            console.log($nextHotel);
            $('.hotel-section-' + section + ' .hotel-content').addClass('hidden');
            $nextHotel.removeClass('hidden');
        });
    }

    let $bookBtn = $('.book-btn');
    let $contactForm = $('#contact-form');

    if ($bookBtn && $contactForm) {
        let $successContainer = $('.request-success');
        let $errorContainer = $('.request-error');
        let $loader = $('.request-sending');
        let $sendEmailBtn = $('#sendEmail');

        $bookBtn.click(function(){
            $successContainer.addClass('hidden');
            $errorContainer.addClass('hidden');
            $contactForm.find('input.form-control').val('');
            $('#booking-popup').modal();
        });

        $contactForm.submit(function(event) {
            event.preventDefault();

            $loader.removeClass('hidden');
            $successContainer.addClass('hidden');
            $errorContainer.addClass('hidden');
            $sendEmailBtn.addClass('hidden');

            let data = $contactForm.serializeArray();

            $.ajax( {
                type: "POST",
                url: $contactForm.attr( 'action' ),
                data: data,
                success: function(response) {
                    $loader.addClass('hidden');
                    $successContainer.removeClass('hidden');
                },
                error: function(error) {
                    $loader.addClass('hidden');
                    $sendEmailBtn.removeClass('hidden');
                }
            } );
        });
    }
});
